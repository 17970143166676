.slideshow .slide {
    position: relative;
    width: 100%;
    grid-area: 1/1/2/4;
}

 /* Next */
.slideshow.next .slide-enter {
    transform: translateX(100%);
}
.slideshow.next .slide-enter-active {
    transform: translateX(0%);
    transition: transform 700ms ease-in-out;
}
.slideshow.next .slide-exit {
    transform: translateX(0%);
}
.slideshow.next .slide-exit-active {
    transform: translateX(-100%);
    transition: transform 700ms ease-in-out;
}

/* Prev */
.slideshow.prev .slide-enter {
    transform: translateX(-100%);
}
.slideshow.prev .slide-enter-active {
    transform: translateX(0%);
    transition: transform 700ms ease-in-out;
}
.slideshow.prev .slide-exit {
    transform: translateX(0);
}
.slideshow.prev .slide-exit-active {
    transform: translateX(100%);
    transition: transform 700ms ease-in-out;
}





